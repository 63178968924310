.Keyboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Key {
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;

    border: solid;
    border-color: var(--darkest);
    border-radius: 0.2em;
    margin: 0.1em;

    height: min(7vh, 12vw);
    width: min(5vh, 8vw);
    font-size: min(4vh, 7vw);
}

.Backspace {
    background-color: transparent;
    border: none;
}

.Return {
    padding: 0.1em;
    margin-left: 0.4em;
    background-color: var(--darkest);
    border-radius: 0.3em;

    border: none;
}