.Grid {
    padding: 0.05em;
    display: flex;
    flex-direction: column;
}

.Word {
    display: flex;
    flex-direction: row;
}

.Char {
    width: min(7vh, 12vw);
    height: min(7vh, 12vw);
    font-size: min(6vh, 10vw);

    border: solid;
    border-color: var(--darkest);
    border-radius: 0.1em;

    color: white;

    margin: 0.1em;

    display: flex;
    justify-content: center;
    align-items: center;
}