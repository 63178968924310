.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.nav {
  width: 100vw;
}

.content {
  flex-grow: 1;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}