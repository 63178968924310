.ColumnContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 80vw;
  align-items: flex-start;
  justify-content: center;
  margin-top: 3vh;
  margin-bottom: 6vh;
}

.Column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.card {
  margin: 10px;
  transition: all 300ms;
  background-color: white;
}

.card:hover {
  filter: brightness(95%);
  transition: all 200ms;
  transform: scale(1.05);
  backface-visibility: hidden;
}

.card-header {
  padding: 0;
}

.CardText {
  font-size: calc((10px + 2vmin) / 2);
}

.CardHeader {
  font-size: calc((10px + 2vmin));
}

a {
  color: inherit;
  text-decoration: none !important;
}


a:hover {
  color: inherit;
}