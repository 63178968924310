.HomeLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  min-width: 100vw;
  background-color:white;

  .CardGroupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    margin-bottom: 100px;
  }

  .CardGroupHeader {
    display: inline-block;
    text-align: start;
    font-size: calc(min(calc(10px + 2vw), 8vmin) * 1.5);
    font-weight: 500;
  }
}