.Layout {
    display: flex;
    flex-direction: column;
    height: 100vh;

    background-color: white;
}

.WordArea {
    height: 60vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.KeysArea {
    height: 30vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}